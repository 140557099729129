// Layout

// Variables
$layout-header-background: $white;
$layout-header-height: 64px;

$layout-header-border: $light-gray;
$layout-content-background: none;
$layout-footer-background: $white;
$layout-footer-border: $light-gray;

.app-container > .ant-layout {
    > .ant-layout-sider {
        /*
        overflow: auto;
        height: 100vh;
        */
        position: fixed;
        left: 0;
        z-index: 101;

        .logo {
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            margin-left: 0;
            margin-top: 0;
            height: $layout-header-height;
            line-height: $layout-header-height;

            background: $layout-header-background;

            .logo-icon {
                width: 0;
                visibility: hidden;
                transition: width .2s;
            }

            .logo-text {
                transition: width .2s;
                width: 110px;
            }
        }
        .harbour-logo {
            text-align: center;
            margin-top: 16px;
            margin-bottom: 8px;
            img {
                border-radius: 100%;
                height: 150px;
                transition: .2s all;
                background: $white;
            }
        }
        .harbour-name {
            color: rgba(255, 255, 255, 0.65);
            text-align: center;
            margin-bottom: 16px;
        }
        .ant-menu {
            margin-bottom: 50px;
        }

        .ant-badge {
            right: 8px;
            position: absolute;
            top: 10px;
            .ant-badge-count {
                @include variable(background-color, --theme-primary-color, $primary-color);
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }

        .scrollarea {
            height: 100vh;
        }

        &.ant-layout-sider-collapsed {
            .harbour-name {
                display: none;
            }
            .harbour-logo img {
                height: 50px;
            }

            .logo-icon {
                width: 50px;
                visibility: visible;
            }

            .logo-text {
                width: 0;
                visibility: hidden;
            }
        }
    }
    > .ant-layout > .ant-layout-header {
        background: $layout-header-background;
        height: $layout-header-height;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
        display: flex;
        z-index: 100;

        position: fixed;
        width: 100%;

        .welcome-message {
            width: 100%;
            text-align: right;
            padding-right: 32px;
        }
        .profile-btn {
            cursor: pointer;
            margin-top: 10px;
            @include variable(background-color, --theme-primary-color, $primary-color);
        }
    }

    > .ant-layout > .ant-layout-content {
        margin-top: 64px;
        padding: 16px;
    }

    > .ant-layout > div {
        transition: 0.2s padding, 0.2s margin;
    }
}

.layout-user-menu {
    .ant-popover-inner-content {
        display: flex;
        flex-direction: column;
        padding: 0;

        button {
            min-width: 200px;
            border-radius: 0;
            border-top: none;
            border-left: none;
            border-right: none;
        }
        button:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        button:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom: none;
        }
    }
}

.layout-select-harbour {
    .ant-popover-inner-content {
        padding: 0;
    }
    .ant-popover-inner-content .ant-btn {
        width: 100%;
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
    }
}
