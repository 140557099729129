.document-list {
    .__title {
        margin: 16px 0 8px 5px;
        .ant-checkbox-wrapper {
            display: flex;
            align-items: center;
            h3 {
                margin-bottom: 0;
                font-weight: bold;
            }
        }
        &:first-child {
            margin-top: 0;
        }
    }
    .__document-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .__checkbox {
            flex: 1;
        }
        .__actions {
            display: flex;
            align-items: center;
            > * {
                margin-right: 4px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}