.table-column-filter-dropdown {
    background: white;
    .list-row {
        padding-left: 8px;
        overflow: hidden;
        white-space: nowrap;
    }
    > .ReactVirtualized__List {
        padding-top: 8px;
    }
}
